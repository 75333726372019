const React = require('react');
const Head = require('nordic/head');
const Style = require('nordic/style');
const serialize = require('serialize-javascript');
const injectI18n = require('nordic/i18n/injectI18n');
const { string, shape } = require('prop-types');
const Script = require('nordic/script');
const RedirectModal = require('./components/RedirectModal');

const View = ({
  device,
  siteId,
  translations,
  domain,
  modalProps,
  imagePrefix,
  deviceType,
}) => (
  <div className="tucM3redirect">
    <Head>
      <title>{domain}</title>
    </Head>
    <Style href="tucM3redirect.css" />
    <Script>
      {`
        window.__PRELOADED_STATE__ = ${serialize(
        {
          device,
          siteId,
          translations,
          domain,
          modalProps,
          imagePrefix,
          deviceType,
        },
        { isJSON: true },
      )};
      `}
    </Script>
    <Script src="vendor.js" />
    <Script src="tucM3redirect.js" />
    <RedirectModal {...{ ...modalProps, deviceType }} />
  </div>
);

View.propTypes = {
  device: string.isRequired,
  siteId: string.isRequired,
  translations: shape({}),
  domain: string.isRequired,
  modalProps: shape({}),
  imagePrefix: string,
  deviceType: string,
};
module.exports = injectI18n(View);
