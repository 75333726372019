const React = require('react');
const PropTypes = require('prop-types');
const { Modal } = require('@andes/modal');
const { Button } = require('@andes/button');
const { Image } = require('nordic/image');

const RedirectModal = ({
  deviceType,
  title,
  description,
  link,
  asset,
  buttonText,
  isOpen,
}) => {
  const isMobile = deviceType === 'mobile';
  const screenModalType = isMobile ? 'full' : 'small';
  return (
    <Modal
      closable={false}
      open={isOpen}
      title={title}
      maxWidth={500}
      type={screenModalType}
      className="tucM3redirect__modal"
      media={{
        asset: (
          <Image
            alt="platform logo"
            src={asset}
            lazyload="off"
            className="tucM3redirect__modal-image"
          />
        ),
      }}
      actions={{
        primary: (
          <Button href={link} fullWidth={isMobile}>
            {buttonText}
          </Button>
        ),
      }}
    >
      <p>{description}</p>
    </Modal>
  );
};

RedirectModal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  deviceType: PropTypes.string.isRequired,
  asset: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

RedirectModal.defaultProps = {
  deviceType: 'desktop',
};

module.exports = RedirectModal;
