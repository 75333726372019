/**
 * Polyfills
 */
require('./polyfills');
const { ImageProvider } = require('nordic/image');
/**
 * Module dependencies
 */
const React = require('react');
const { hydrate } = require('nordic/hydrate');
const I18n = require('nordic/i18n');
const I18nProvider = require('nordic/i18n/I18nProvider');
const { AndesProvider } = require('@andes/context');
const View = require('../pages/tucM3redirect/view');
/**
 * Get server state
 */
const {
  siteId,
  translations,
  domain,
  imagePrefix,
  modalProps,
  device,
  deviceType,
} = window.__PRELOADED_STATE__;

/**
 * i18n
 */
const i18n = new I18n({ translations });
/**
 * Mount View on client
 */
hydrate(
  <I18nProvider i18n={i18n}>
    <ImageProvider prefix={imagePrefix}>
      <AndesProvider locale={i18n.locale} device={device}>
        <View
          siteId={siteId}
          domain={domain}
          modalProps={modalProps}
          imagePrefix={imagePrefix}
          deviceType={deviceType}
        />
      </AndesProvider>
    </ImageProvider>
  </I18nProvider>,
  document.getElementById('root-app'),
);
